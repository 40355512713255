import ApiCommerce from '@/core/apis/ApiCommerce';
import { IDadosLogin } from '@/core/models/IDadosLogin';
import { IResposta } from '@/core/models/IResposta';
import { IRetornoAutenticacao } from '@/core/models/IRetornoAutenticacao';
import { IDTOUsuarioMeusDados } from '@/models/DTO/Usuarios/IDTOUsuarioMeusDados';
import { IConfiguracao } from '@/models/Entidades/IConfiguracao';
import { IParceiro } from '@/models/Entidades/IParceiro';
import { IUsuario } from '@/models/Entidades/IUsuario';
import { ETipoNotificacaoEmail } from '@/models/Enumeradores/ETipoNotificacaoEmail';

class ServicoApp {
    private endPoint = 'app';

    private apiCommerce = new ApiCommerce();

    public async obterDataAtual(): Promise<string> {
      const result: any = await this.apiCommerce.get(`${this.endPoint}/data-atual`);
      return result.data;
    }

    public async login(dadosLogin: IDadosLogin): Promise<IRetornoAutenticacao> {
      const result: any = await this.apiCommerce.post('autenticacao/login', dadosLogin);
      return result.data;
    }

    public async obterDadosUsuarioAutenticado(): Promise<IUsuario> {
      const result: any = await this.apiCommerce.get('autenticacao/dados');
      return result.data;
    }

    public async atualizarDadosUsuarioAutenticado(meusDados: IDTOUsuarioMeusDados): Promise<IResposta> {
      const result: any = await this.apiCommerce.put('autenticacao/dados', meusDados);
      return result.data;
    }

    public async atualizarToken(): Promise<IRetornoAutenticacao> {
      const result: any = await this.apiCommerce.post('autenticacao/atualizar-token');
      return result.data;
    }

    public async deslogar(): Promise<IRetornoAutenticacao> {
      const result: any = await this.apiCommerce.post('autenticacao/logout');
      return result.data;
    }

    public async obterConfiguracoes(): Promise<IConfiguracao> {
      const result: any = await this.apiCommerce.get('configuracoes');
      return result.data;
    }

    public async atualizarConfiguracoes(configuracoes: IConfiguracao): Promise<IResposta> {
      const result: any = await this.apiCommerce.put('configuracoes', configuracoes);
      return result.data;
    }

    public async atualizarConfiguracoesEmails(configuracoes: IConfiguracao): Promise<IResposta> {
      const result: any = await this.apiCommerce.put('configuracoes/emails', configuracoes);
      return result.data;
    }

    public async obterDadosMeuNegocio(): Promise<IParceiro> {
      const result: any = await this.apiCommerce.get('meu-negocio');
      return result.data;
    }

    public async atualizarDadosMeuNegocio(parceiro: IParceiro): Promise<IResposta> {
      const result: any = await this.apiCommerce.put('meu-negocio', parceiro);
      return result.data;
    }

    public obterDescricaoTipoNotificacaoEmail(tipoNotificacao:ETipoNotificacaoEmail):string {
      let descricao = '';
      switch (tipoNotificacao) {
        case ETipoNotificacaoEmail.Padrao:
          descricao = 'Padrão';
          break;

        case ETipoNotificacaoEmail.NovaReserva:
          descricao = 'Nova Reserva';
          break;

        case ETipoNotificacaoEmail.ReservaConfirmada:
          descricao = 'Reserva Confirmada';
          break;

        case ETipoNotificacaoEmail.ReservaCancelada:
          descricao = 'Reserva Cancelada';
          break;

        default:
          break;
      }
      return descricao;
    }

    public obterDestintariosTipoNotificacaoEmail(tipoNotificacao:ETipoNotificacaoEmail, destinatarios:string):string {
      let descricaoDestinatarios = '';
      switch (tipoNotificacao) {
        case ETipoNotificacaoEmail.Padrao:
        case ETipoNotificacaoEmail.NovaReserva:
        case ETipoNotificacaoEmail.ReservaCancelada:
        case ETipoNotificacaoEmail.ReservaConfirmada:
          descricaoDestinatarios = 'Clientes e Administradores';
          break;

        default:
          break;
      }
      return descricaoDestinatarios;
    }

    public preencherDestintariosTipoNotificacaoEmail(tipoNotificacao:ETipoNotificacaoEmail):boolean {
      let preencher = false;
      switch (tipoNotificacao) {
        case ETipoNotificacaoEmail.Padrao:
        case ETipoNotificacaoEmail.NovaReserva:
        case ETipoNotificacaoEmail.ReservaCancelada:
        case ETipoNotificacaoEmail.ReservaConfirmada:
          preencher = true;
          break;

        default:
          break;
      }
      return preencher;
    }
}
export default ServicoApp;
